<template>
  <div class="page-general-info-2  animate__fadeIn">
    <div class="input-search-container mb-3" v-if="!gpsManual && gpsArray.length>0">
      <p class="text-left text-white">Which GP surgery are you currently registered at?</p>
      <vSelect
          class="w-100 select maxh"
          label="gps"
          :clearable="false"
          :options="gpsArray"
          :placeholder="`Please select your GP from the list...`"
          :multiple="false"
          @option:selected="(ev) => updateFullGP(ev)"
          @search="onSearch"
      >
        <template slot="option" slot-scope="option">
          {{ option.gps }}
        </template>
      </vSelect>
    </div>

    <!-- GPS Manual -->
    <div class="mb-1" v-if="gpsManual || gpsArray.length==0">
      <div>
        <p class="text-left text-white">Your current doctor’s surgery name</p>
        <input
            type="text"

            class="w-100 p-1"
            :value="this.gpName"
            @input="(e) => updateGPName(e.target.value)"
        />
      </div>
      <div style="margin-top:5%">
        <p class="text-left text-white">The first line of your current GP surgery's address</p>
        <input
            type="text"

            class="w-100 p-1"
            :value="this.addressFirstLine"
            @input="(e) => updateGPAddress(e.target.value)"
        />
      </div>
      <div style="margin-top:5%">
        <p class="text-left text-white">Your current GP surgery's city/town</p>
        <input
            type="text"

            class="w-100 p-1"
            :value="this.addressTown"
            @input="(e) => updateGPTown(e.target.value)"
        />
      </div>
      <div style="margin-top:5%">
        <p class="text-left text-white">Your current GP surgery's postcode</p>
        <input
            type="text"

            class="w-100 p-1"
            :value="this.addressPostcode"
            @input="(e) => updateGPPostcode(e.target.value)"
        />
      </div>
</div>
    <div class="chip-list-wrapper mb-2">
      <div style="margin-top:-1.5rem"
          class="chip me-1"
          v-for="(item, index) in gpsSelected"
          :key="index"
          @click="deleteGPS(index)"
      >
        <i class="fa-sharp fa-solid fa-circle-check"></i>
        <p class="ms-1 mb-0">{{ item }}</p>
      </div>
    </div>

    <!-- Enter your GP manually -->

      <div v-if="gpsArray.length>0" style="width: 100%;display: flex; justify-content: space-between; align-items: center;">
        <div style="width: 100%;text-align:right">
          <p class="mb-0 fs-xs">Enter your GP manually</p>
        </div>
        <div style="width: 13%;margin-left: 3%">
          <label class="switch">

            <input
                type="checkbox"
                @change="(payload) => updateManual()"
                :checked="gpsManual"
            />
            <span class="slider round"></span>s
          </label>
        </div>

      </div>
    </div>


</template>
<script>
import vSelect from "vue-select";
import axiosCustom from '@/libs/axios'

export default {
  name: "GPSelect",
  props: {
    postcodeTo: [String],
    postcodeFrom: [String]
  },
  components: {
    vSelect,
  },
  data() {
    return {
      errors: {
        dentistsSelected: false,
        gpsSelected: false,
      },
      gpsArray: [],
      gpsCompleteArray: [],
      gpsManual:false,
      gpName:"",
      addressFirstLine:"",
      addressTown:"",
      addressPostcode:"",

      search: "",
      gpsSelected: [],
    };
  },
  mounted() {
    this.getGPS();
  },
  methods: {
    onSearch(search, loading) {
      // loading(true)
      this.search = search;
    },
    updateFullGP(value) {
      this.gpsCompleteArray.forEach((gp) => {
        if (gp.name === value) {
          this.$emit("gpName", value);
          this.$emit("addressFirstLine", gp.address1 + gp.address2);
          this.$emit("addressTown", gp.town);
          this.$emit("addressPostcode", gp.postcode);
        }
      });

    },
    updateGPName(value){
      this.$emit("gpName", value);
    },
    updateGPAddress(value){
      this.$emit("addressFirstLine", value);
    },
    updateGPTown(value){
      this.$emit("addressTown", value);
    },
    updateGPPostcode(value){
      this.$emit("addressPostcode", value);
    },
    deleteGPS(index) {
      this.gpsSelected.splice(index, 1);
    },
    updateValue(itemName, value) {

      itemName=value;
    },
    updateManual() {
      this.gpsManual = !this.gpsManual;

    },
    getGPS() {
      axiosCustom
          .get(`/GPS/fetch?moveFromPostcode=${this.postcodeFrom}&moveToPostcode=${this.postcodeTo}`)
          .then(({ data }) => {
            const gpsArray = [];
            this.gpsCompleteArray=data;
            data.forEach((gps) => {
              if (gps.name !== "") {
                gpsArray.push(gps.name);
              }
            });
            this.gpsArray = [...new Set(gpsArray)].sort();
          });
    },
  },
  watch: {

  },
};
</script>
<style lang="scss">
.page-general-info-2 {
  .maxh{
    max-height: 50px;
  }
  .icon-search {
    color: #8a8a8a;
    float: left;
    margin-left: 15px;
    margin-top: -65px;
    position: relative;
    z-index: 2;
    font-size: 23px;
  }

  .box-answers {
    border: 1px solid transparent;
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: 14px 34px;
    text-align: center;
    cursor: pointer;
  }

  .box-answers-services {
    border: 1px solid transparent;
    background-color: white;
    color: black;
    border-radius: 6px;
    width: 110px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  .checked {
    border: 1px solid #2ec989;
    background-color: #1e9d68;
    color: white;
  }

  .v-select {
    color: black;
    font-weight: 600;

    .vs__actions {
      margin-right: 5px;
    }

    .vs__dropdown-toggle {
      height: 55px;
      border: none;
    }

    .vs__search::placeholder {
      color: #8a8a8a;
    }
    .vs__dropdown-menu {
      p {
        font-weight: 200;
      }
    }

    .enter-manually {
      cursor: pointer;
      font-size: 12px;
      color: #8a8a8a;
      text-decoration: underline;
    }
  }

  .chip-list-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .chip {
      display: flex;
      align-items: center;
      padding: 7px;
      height: 30px;
      border-radius: 25px;
      background-color: #f1f1f1;

      p {
        color: black;
        font-size: 14px;
      }

      .fa-circle-check {
        color: #1e9d68;
        font-size: 16px;
      }
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 30px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(35px);
    -ms-transform: translateX(35px);
    transform: translateX(35px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}
</style>
